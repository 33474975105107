// import node module libraries
import { Fragment } from 'react';

// import sub components
import HeroAcademy from './HeroAcademy';
import AcademyStats from './AcademyStats';
import MostPopularCourses from './MostPopularCourses';
import BecomeAnInstructor from './BecomeAnInstructor';
import WhatCustomersSay from './WhatCustomersSay';
import Features4Columns from './Features4Columns';
import HeroTyped from './HeroTyped';

const HomeAcademy = () => {
	return (
		<Fragment>
			{/* Hero Academy banner section */}
			<HeroAcademy />

			{/* Most Popular Courses */}
			<MostPopularCourses />

			{/* Various acedamy statistics  */}
			<HeroTyped />

			{/* Various acedamy statistics  */}
			<AcademyStats />

			{/* Various acedamy statistics  */}
			{/*<Features4Columns />*/}

			{/* Become an instructor */}
			{/*<BecomeAnInstructor />*/}

			{/* What our customers say */}
			{/*<WhatCustomersSay />*/}
		</Fragment>
	);
};
export default HomeAcademy;

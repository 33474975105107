import { Fragment } from 'react';
import { Col, Row, Container, Tab, Nav } from 'react-bootstrap';
import GetEnrolledCourseCard from 'components/marketing/common/cards/GetEnrolledCourseCard';
import { AllCoursesData } from 'data/slider/AllCoursesData';

const MostPopularCourses = () => {
    const tabs = ['All Courses', 'Artificial Intelligence', 'Big Data', 'Software', 'Web', 'Mobile'];

    const tabIndices = {
        "All Courses": {min: 0, max: 20},
        "Artificial Intelligence": {min: 0, max: 4},
        "Big Data": {min: 4, max:10},
        "Software": {min: 10, max: 14},
        "Web": {min: 14, max: 16},
        "Mobile": {min: 16, max: 17}
    };

    return (
        <Fragment>
            <section className="pb-lg-14 pb-8 bg-white">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-6">
                                <h2 className="mb-1 h1">Nos Formations les Plus Populaires</h2>
                                <p>
                                    Retrouvez nos formations les plus populaires auprès de la communauté SmartDZAcademy pour l'année 2023.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Tab.Container defaultActiveKey="All Courses">
                                <Nav className="nav-lb-tab  mb-6 bg-gray-200 px-5 rounded-3 ">
                                    {tabs.map((tab, index) => (
                                        <Nav.Item key={index} className={index === 0 ? 'ms-0' : ''}>
                                            <Nav.Link eventKey={tab} className="mb-sm-3 mb-md-0">
                                                {tab}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                <Tab.Content>
                                    {tabs.map((tab, index) => {
                                        const {min, max} = tabIndices[tab];
                                        const actualMax = Math.min(max, AllCoursesData.length);
                                        return (
                                            <Tab.Pane eventKey={tab} className="pb-4 p-4 ps-0 pe-0" key={index}>
                                                <Row>
                                                    {AllCoursesData.slice(min, actualMax).map((item) => (
                                                        <Col lg={3} md={6} sm={12} key={item.id}>
                                                            <GetEnrolledCourseCard item={item} />
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Tab.Pane>
                                        );
                                    })}
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default MostPopularCourses;

// import node module libraries
import { Col, Row, Container } from 'react-bootstrap';

// import custom components
import StatTopBigIcon from 'components/marketing/common/stats/StatTopBigIcon';

const AcademyStats = () => {
	return (
		<section className="pb-14 bg-white">
			<Container>
				<Row>
					<Col
						lg={3}
						md={6}
						className="border-top-md border-bottom border-end-md "
					>
						<StatTopBigIcon
							title="Obtiens ton attestation de réussite à l'issue de la formation"
							value="Certificate"
							iconName="award"
							colorVariant="info"
						/>
					</Col>
					<Col
						lg={3}
						md={6}
						className="border-top-md border-bottom border-end-lg "
					>
						<StatTopBigIcon
							title="Sois mentoré par des experts passionnés, prêts à te guider vers l'excellence."
							value="Instructors"
							iconName="users"
							colorVariant="warning"
						/>
					</Col>
					<Col
						lg={3}
						md={6}
						className="border-top-lg border-bottom border-end-md "
					>
						<StatTopBigIcon
							title="Découvre nos cours approfondis, conçus pour te fournir les compétences les plus pertinentes"
							value="Courses"
							iconName="tv"
							colorVariant="primary"
						/>
					</Col>
					<Col lg={3} md={6} className="border-top-lg border-bottom ">
						<StatTopBigIcon
							title="Bénéficie de Labs et Projets de haut niveau, axés sur des cas d'utilisation réels du secteur professionnel."
							value="Labs & Projects"
							iconName="film"
							colorVariant="success"
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default AcademyStats;
